import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`React Ensemble has first-class support for `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{`, a type-safe extension of JavaScript.`}</p>
    <p>{`This means that if you import React Ensemble components into TypeScript files, they will already have all the type definitions.
If you use an IDE with a TypeScript server like `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/"
      }}>{`Visual Studio Code`}</a>{`, you may get type suggestions even if you're writing JavaScript.`}</p>
    <h2 {...{
      "id": "type-imports"
    }}>{`Type Imports`}</h2>
    <p>{`Most of the type definitions used in React Ensemble are publicly exported and are considered part of the `}<a parentName="p" {...{
        "href": "/docs/api/types"
      }}>{`public API`}</a>{`.`}</p>
    <p>{`You can import types just like other modules from React Ensemble:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Timeline, TrackRegion } from "react-ensemble";
`}</code></pre>
    <h2 {...{
      "id": "animation-state-type"
    }}>{`Animation State Type`}</h2>
    <p>{`Throughout React Ensemble's type definitions, you may find references to `}<inlineCode parentName="p">{`<State>`}</inlineCode>{`. For example, the `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` definition:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const Controller: <State extends object = any>(
  props: ControllerProps<State>
) => JSX.Element | null;
`}</code></pre>
    <p><inlineCode parentName="p">{`<State>`}</inlineCode>{` refers to your animation's custom state, which is the schema of state properties generated on every frame.
You can define an interface for your animation's state to make your use of React Ensemble types even more explicit.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { TrackRegion } from "react-ensemble";

interface AnimState {
  x: number;
  y: number;
}

const track: TrackRegion<AnimState>[] = [
  {
    start: 1000,
    state: {
      x: { to: 10 },
      y: { to: 10 }
    }
  },
  {
    start: 2000,
    duration: 500,
    state: {
      y: { to: [10] } // Will throw type error! y must be a number.
    }
  }
];
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      